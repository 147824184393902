import * as d3 from "d3";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function getRandomIntBetween(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

function getData(n = 10, m = 10, normal = true) {
  var n_components = getRandomIntBetween(6, m + 1);
  var x = getRandomIntBetween(0, 5);
  var y = getRandomIntBetween(15, 40);
  var displacement = getRandomInt(n - Math.max(x, y));
  x = x + displacement
  y = y + displacement

  var anomaly_interval = [
    Math.min(x, y),
    Math.max(x, y)
  ];

  var x_values = Array(n).fill().map((element, index) => index);
  var y_values = Array(n).fill(0);

  for (var i = 0; i < n_components; i++) {
    var a1 = getRandomInt(n_components) / n_components;
    var b1 = getRandomInt(n_components) / n_components;
    var zero1 = getRandomInt(1 + 1);

    var a2 = getRandomInt(n_components) / n_components;
    var b2 = getRandomInt(n_components) / n_components;
    var zero2 = getRandomInt(1 + 1);

    for (var j = 0; j < n; j++) {
      y_values[j] = y_values[j] + (zero1 * b1 * Math.sin(a1 * x_values[j]));
      y_values[j] = y_values[j] + (zero2 * b2 * Math.cos(a2 * x_values[j]));
    }

  }

  var res = [];

  if (normal) {
    for (var i = 0; i < x_values.length; i++) {
      res.push([x_values[i], y_values[i]])
    }
    return {
      anomaly: false,
      data: res,
    }
  } else {
    for (var i = anomaly_interval[0]; i < anomaly_interval[1]; i++) {
      y_values[i] = y_values[i] * 0.45;
    }
    for (var i = 0; i < x_values.length; i++) {
      res.push([x_values[i], y_values[i]])
    }
    return {
      anomaly: true,
      interval: anomaly_interval,
      data: res,
    };
  }
}

var n_items = 200;

// set the dimensions and margins of the graph
var margin = { top: 10, right: 30, bottom: 30, left: 60 },
  width = 460 - margin.left - margin.right,
  height = 200 - margin.top - margin.bottom;

// append the svg object to the body of the page
var svg = d3.select("#graph1").append("svg")
  .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`).append("g")
  .attr("class", "main")
  .attr("transform",
    "translate(" + margin.left + "," + margin.top + ")");

var svg2 = d3.select("#graph2").append("svg")
  .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`).append("g")
  .attr("class", "main")
  .attr("transform",
    "translate(" + margin.left + "," + margin.top + ")");

var svg3 = d3.select("#graph3").append("svg")
  .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`).append("g")
  .attr("class", "main")
  .attr("transform",
    "translate(" + margin.left + "," + margin.top + ")");

var y_scale = null;
var x_scale = null;

var points = 0;
var counter = 0;
var timeleft = 30;
var inGame = false;
var samples = [];

function countDown() {
  setTimeout(() => {

    if (timeleft > 0) {
      timeleft = timeleft - 1;
      updateTimeleft();
      countDown();
    } else {
      wrong();
    }

  }, 1000);
}

function start() {

  if (counter > 0 && !inGame) {
    countDown();
    inGame = true;

    if (window.fathom) {
      window.fathom.trackGoal('MOEDIJDT', 0);
    }
  }

  svg.selectAll("*").remove();
  svg2.selectAll("*").remove();
  svg3.selectAll("*").remove();

  var t1 = getData(n_items, 10, true);
  var t2 = getData(n_items, 10, true);
  var t3 = getData(n_items, 10, false);

  samples = [t1, t2, t3];
  shuffleArray(samples);

  let max1 = d3.max(t1.data, function (d) { return +d[1]; });
  let max2 = d3.max(t2.data, function (d) { return +d[1]; });
  let max3 = d3.max(t3.data, function (d) { return +d[1]; });
  let tot_max = d3.max([max1, max2, max3]) + 10;

  let min1 = d3.min(t1.data, function (d) { return +d[1]; });
  let min2 = d3.min(t2.data, function (d) { return +d[1]; });
  let min3 = d3.min(t3.data, function (d) { return +d[1]; });
  let tot_min = d3.min([min1, min2, min3]) - 10;


  // Add X axis --> it is a date format
  x_scale = d3.scaleLinear()
    .domain([0, n_items - 1])
    .range([0, width]);
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .attr("class", "axis")
    .call(d3.axisBottom(x_scale).ticks(5));

  svg2.append("g")
    .attr("transform", "translate(0," + height + ")")
    .attr("class", "axis")
    .call(d3.axisBottom(x_scale).ticks(5));

  svg3.append("g")
    .attr("transform", "translate(0," + height + ")")
    .attr("class", "axis")
    .call(d3.axisBottom(x_scale).ticks(5));

  // Add Y axis
  y_scale = d3.scaleLinear()
    .domain([tot_min, tot_max])
    .range([height, 0]);
  svg.append("g")
    .attr("class", "axis")
    .call(d3.axisLeft(y_scale).ticks(2));
  svg2.append("g")
    .attr("class", "axis")
    .call(d3.axisLeft(y_scale).ticks(2));

  svg3.append("g")
    .attr("class", "axis")
    .call(d3.axisLeft(y_scale).ticks(2));

  // Add the line
  svg.append("path")
    .datum(samples[0].data)
    .attr("fill", "none")
    .attr("stroke", "#00ff75")
    .attr("stroke-width", 1.5)
    .attr("d", d3.line()
      .x(function (d) { return x_scale(d[0]) })
      .y(function (d) { return y_scale(d[1]) })
    )

  svg2.append("path")
    .datum(samples[1].data)
    .attr("fill", "none")
    .attr("stroke", "#00ff75")
    .attr("stroke-width", 1.5)
    .attr("d", d3.line()
      .x(function (d) { return x_scale(d[0]) })
      .y(function (d) { return y_scale(d[1]) })
    )

  svg3.append("path")
    .datum(samples[2].data)
    .attr("fill", "none")
    .attr("stroke", "#00ff75")
    .attr("stroke-width", 1.5)
    .attr("d", d3.line()
      .x(function (d) { return x_scale(d[0]) })
      .y(function (d) { return y_scale(d[1]) })
    )

  counter = counter + 1;
}


start();

document.getElementById('link1').addEventListener('click', function (eventObj) {
  plot_anomaly();
  if (!samples[0].anomaly) {
    wrong_answer();
    decPoints();
  } else {
    d_correct()
    incPoints();
  }

  setTimeout(() => {
    d_correct_hide();
    wrong_answer_clear();
    start();
  }, 500);
  eventObj.preventDefault();
});

document.getElementById('link2').addEventListener('click', function (eventObj) {
  plot_anomaly();
  if (!samples[1].anomaly) {
    wrong_answer();
    decPoints();
  } else {
    d_correct()
    incPoints();
  }

  setTimeout(() => {
    d_correct_hide();
    wrong_answer_clear();
    start();
  }, 500);
  eventObj.preventDefault();
});

document.getElementById('link3').addEventListener('click', function (eventObj) {
  plot_anomaly();
  if (!samples[2].anomaly) {
    wrong_answer();
    decPoints();
  } else {
    d_correct()
    incPoints();
  }

  setTimeout(() => {
    d_correct_hide();
    wrong_answer_clear();
    start();
  }, 500);
  eventObj.preventDefault();
});

document.getElementById('restart').addEventListener('click', function (eventObj) {
  points = 0;
  counter = 0;
  timeleft = 30;
  inGame = false;
  var point_label = document.getElementById('points');
  point_label.textContent = points;
  var point_label2 = document.getElementById('points2');
  point_label2.textContent = points;
  var el = document.getElementById('gtime');
  el.textContent = timeleft;
  start();
  wrong_hide();
  eventObj.preventDefault();
});

function plot_anomaly() {
  samples.forEach((item, index) => {
    if (item.anomaly) {
      var svg_el = null;
      if (index == 0) {
        svg_el = svg;
      } else if (index == 1) {
        svg_el = svg2;
      } else {
        svg_el = svg3;
      }

      var f_data = item.data.filter((data) => data[0] > item.interval[0] && data[0] < item.interval[1]);

      svg_el.append("path")
        .datum(f_data)
        .attr("fill", "none")
        .attr("stroke", "#eef1fb")
        .attr("stroke-width", 4.0)
        .attr("d", d3.line()
          .x(function (d) { return x_scale(d[0]) })
          .y(function (d) { return y_scale(d[1]) })
        )

    }
  })

}

function d_correct() {
  var el = document.getElementById('correct');
  el.style.display = "block";
}

function d_correct_hide() {
  var el = document.getElementById('correct');
  el.style.display = "none";
}

function wrong() {
  var el1 = document.getElementById('overlay');
  el1.style.display = "block";

  var el = document.getElementById('wrong');
  el.style.display = "flex";
}

function wrong_hide() {
  var el1 = document.getElementById('overlay');
  el1.style.display = "none";

  var el = document.getElementById('wrong');
  el.style.display = "none";
}

function wrong_answer() {
  var el1 = document.getElementById('wronganswer');
  el1.style.display = "block";
}

function wrong_answer_clear() {
  var el1 = document.getElementById('wronganswer');
  el1.style.display = "none";
}

function incPoints() {
  points += 1;
  var point_label = document.getElementById('points');
  point_label.textContent = points;

  var point_label2 = document.getElementById('points2');
  point_label2.textContent = points;
}

function decPoints() {
  if (points > 0) {
    points -= 1;
  }

  var point_label = document.getElementById('points');
  point_label.textContent = points;

  var point_label2 = document.getElementById('points2');
  point_label2.textContent = points;
}

function updateTimeleft() {
  var el = document.getElementById('gtime');
  el.textContent = timeleft;
}
